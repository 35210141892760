@import '../../../../../../node_modules/quill/dist/quill.snow.css';

@quill-size: @font-size-base * 2;

quill-editor {
    --editor-disabled-background: @input-disabled-bg;
    --editor-radius: @border-radius-base;
    --editor-border-color: @border-color-base;
    --editor-border-width: 1px;

    border-radius: var(--editor-radius);
    transition: all 0.3s @ease-in-out, height 0ms;
    border-color: var(--editor-border-color) !important;
    position: relative;
    
    display: flex !important;
    flex-direction: column;
    &[borderless] {
        --editor-radius: 0px;
        --editor-border-width: 0px;
        --editor-disabled-background: @input-disabled-bg;
    }
}

.ql-snow.ql-toolbar {
    padding: 0px;
    border-top-left-radius: var(--editor-radius);
    border-top-right-radius: var(--editor-radius);
    border-width: var(--editor-border-width);
    background-color: @body-background;
    flex-shrink: 0;
    position: sticky;
    top: 0;
}

.ql-snow.ql-container {
    border-bottom-left-radius: var(--editor-radius);
    border-bottom-right-radius: var(--editor-radius);
    border-width: var(--editor-border-width);
    font-family: @font-family !important;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    overflow: hidden;
    flex: 1;
    min-height: 0px;
}

.ql-snow.ql-toolbar,
.ql-container,
.ql-toolbar,
.ql-snow.ql-container {
    border-color: var(--editor-border-color) !important;
    transition: border-color 0.3s @ease-in-out;
}


.ql-snow.ql-container .ql-editor {
    padding: 8px;
    height: initial;
    flex: 1;
}

.ql-toolbar.ql-snow button, 
.ql-toolbar.ql-snow button,
.ql-toolbar.ql-snow .ql-color-picker, 
.ql-toolbar.ql-snow .ql-icon-picker {
    width: @quill-size;
    height: @quill-size;
    padding: 4px;
    display: inline-flex;

    > .ql-picker-label {
        padding: 0;
        border: none;
        display: inline-flex;
    }
}

.ql-snow.ql-toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
}

.ql-snow.ql-toolbar .ql-formats {
    display: inline-flex;
    align-items: center;
    gap: 0px;
    margin: 0;
}

quill-editor.fill-card {
    height: 100%;
    display: flex !important;
    flex-direction: column;

    > .ql-toolbar {
        flex-shrink: 0;
    }

    > .ql-container {
        flex: 1;
    }
}

quill-editor.ng-touched.ng-invalid {
    > .ql-toolbar,
    > .ql-container {
        border-color: @error-color !important;
    }
}

quill-editor[disabled] {
    color: @input-disabled-color;
    cursor: not-allowed;
    .ql-container {
        background-color: var(--editor-disabled-background);
    }

    .ql-toolbar {
        pointer-events: none;
        color: @input-disabled-color;
    }
    
    .ql-stroke { stroke: @input-disabled-color; }
    .ql-fill { fill: @input-disabled-color; }
}

.ql-editor.ql-blank::before {
    color: @input-placeholder-color;
    font-style: normal;
    left: 8px;
    right: 8px;
}