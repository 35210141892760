.ant-select-selector {
    min-height: 28px;
}


.ant-select.ant-select-multiple .ant-select-selection-item {
    height: 22px;
}

.ant-select.ant-select-multiple.rounded-tags .ant-select-selection-item {
    border-radius: 1rem;
    padding-inline-start: 4px;
    padding-inline-end: 4px;
    gap: 4px;
    height: 22px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 20px;
    margin-inline-end: 4px;
}

.ant-input-borderless[disabled] {
    background-color: @input-disabled-bg;
}

.ant-input-group > nz-select:not(:last-child) > .ant-select-selector,
.ant-input-group > nz-date-picker:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-checkbox-inner {
    border-spacing: 0 !important;
}

.ant-input-group > nz-select,
.ant-input-group > nz-date-picker {
    display: table-cell;
    min-width: 0;
}

.ant-input-wrapper > nz-select {
    max-width: 0;
}

.ant-input[type="color"] {
    padding: 0;
    width: 28px;
    overflow: hidden;
    &::-webkit-color-swatch { border: none; }
    &::-moz-color-swatch { border: none; }
}

.ant-radio-group.form-radio-group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    .ant-radio-wrapper {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        gap: 8px;
        min-height: 28px;
        &::after {
            display: none;
        }
    }
    
    .ant-radio {
        top: unset;
        flex-shrink: 0;
    }
    .ant-radio + * {
        padding-right: 0px !important;
        padding-left: 0px !important;
        flex: 1;
    }
}