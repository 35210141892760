.ant-table {
    background-color: transparent !important;
    overflow: auto;
}

.ant-table-content > table,
.ant-table-body > table {
    border-spacing: 0px 0.5rem !important;
    // table-layout: fixed !important;
}

.ant-table-thead > tr > th,
.ant-table-tfoot > tr > th,
.ant-table-tfoot > tr > td {
    background-color: transparent;
    border: none;
    &::before { display: none; }
}


.ant-table-tbody > tr > td {
    background-color: white;
    border-bottom: none;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table-tfoot > tr > th,
.ant-table-tfoot > tr > td {
    padding: 1rem 0.5rem;
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 1rem;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 1rem;
    }
}

.ant-table-tbody > tr > td {
    transition: border-color 0.3s ease;
    border: 1px solid @border-color-split;
    &:not(:first-child) { border-left: none; }
    &:not(:last-child) { border-right: none; }
    &.selected {
        > td {
            border-color: @primary-color;
        }
    }
}


.ant-table-cell-fix-right { right: 0; }
.ant-table-cell-fix-left { left: 0; }


.ant-table.ant-table-small {
    font-size: 10px;
    table { border-spacing: 0px 0.25rem !important;  }
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    .ant-table-tfoot > tr > th,
    .ant-table-tfoot > tr > td {
        padding: 0.25rem 0.25rem;
        &:first-child { padding-left: 0.5rem; }
        &:last-child { padding-right: 0.5rem; }
    }
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table,
.ant-table.ant-table-bordered > .ant-table-container,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    border: none !important;
}